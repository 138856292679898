<template>
  <div v-if="dialog">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              !dealerData._id
                ? $t("title.create_dealer")
                : $t("title.update_dealer")
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="loading" disabled text @click="validate">
              {{ !dealerData._id ? $t("button.create") : $t("button.update") }}
            </v-btn>
            <v-btn v-else text @click="validate">
              {{ !dealerData._id ? $t("button.create") : $t("button.update") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-text-field
                  v-model="dealerData.barcode"
                  :label="$t('input.barcode')"
                  :rules="rules.barcode"
                  type="text"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="dealerData.name"
                  :label="$t('input.dealer_name')"
                  :rules="rules.name"
                  type="text"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="dealerData.dob"
                  :label="$t('input.dealer_dob')"
                  :rules="rules.dob"
                  type="text"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-file-input
                  v-model="dealerData.profile_image"
                  accept="image/*"
                  label="Choose Profile"
                ></v-file-input>
              </v-row>
              <v-row>
                <v-select
                v-model="dealerData.position"
                :items="positions"
                :rules="rules.position"
                label="Position"
              ></v-select>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="dealerData.address"
                  label="Address"
                  
                  type="text"
                ></v-text-field>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
button {
}
</style>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          name: [(v) => !!v || "Username is required"],
          dob: [(v) => !!v || "Date of birth is required"],
          barcode: [(v) => !!v || "Barcode is required"],
          position: [(v) => !!v || "Barcode is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dealerData: { type: Object, default: null },
    // dealerData: {
    //     type: Object,
    //     default: function () {
    //         return {
    //           is_pr: true,
    //           is_pitbox: false,
    //         }
    //     }
    // }
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      images: null,
      positions: ["dealer","pr","pitbox"]
    };
  },
  watch: {
    // users:function(newVal, oldVal){
    // }
    // is_pr(nv) { if (nv) { this.is_pitbox = false; } },
    // is_pitbox(nv) { if (nv) { this.is_pr = false; } }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      console.log("this.selectedFile:", this.selectedFile);
    },
  },
  
};
</script>


